<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li class="has-text-primary-dark-blue">
          <span class="px-2">
            Add-Ons
          </span>
        </li>
      </ul>
    </nav>

    <div class="columns is-multiline">
      <div class="column is-3">
        <b-field>
          <b-input
            placeholder="Pencarian"
            icon="search"
            v-model="search"
            rounded
            @input="searchAddOns"
          ></b-input>
        </b-field>
      </div>

      <div class="column is-2 is-offset-7 ">
        <b-button
          tag="router-link"
          :to="{ name: 'AddAddOns' }"
          type="is-primary-medium-blue is-pulled-right"
        >
          Buat Add-On Baru
        </b-button>
      </div>

      <div class="column is-12">
        <b-select
          v-model="perPage"
          placeholder="Per-Page"
          :value="perPage"
          @input="getAllAddOnsIndex()"
        >
          <option
            v-for="option in perPages"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </b-select>
      </div>

      <div class="column">
        <b-table
          :data="addons"
          :loading="loading"
          paginated
          backend-pagination
          :total="rows"
          :per-page="perPage"
          @page-change="onPageChange"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
        >
          <b-table-column label="No" v-slot="props">
            {{ (page - 1) * perPage + props.index + 1 }}
          </b-table-column>

          <b-table-column field="name" sortable label="Nama" v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column
            field="description"
            sortable
            label="Deskripsi"
            v-slot="props"
          >
            {{ props.row.description }}
          </b-table-column>

          <b-table-column field="status" sortable label="Status" v-slot="props">
            {{ props.row.status }}
          </b-table-column>

          <b-table-column
            field="expiry"
            sortable
            label="Masa Aktif"
            v-slot="props"
          >
            <span v-if="props.row.expiry != null">
              {{ props.row.expiry }} hari
            </span>
            <span v-else>Tidak Ada</span>
          </b-table-column>

          <b-table-column field="price" sortable label="Harga" v-slot="props">
            {{ props.row.price | toCurrency }}
          </b-table-column>

          <b-table-column
            field="discount"
            sortable
            label="Diskon"
            v-slot="props"
          >
            {{ props.row.discount | toCurrency }}
          </b-table-column>

          <b-table-column label="Aksi" v-slot="props">
            <router-link :to="`/addons/${props.row.id}`">
              <img
                :src="require('../../assets/Edit.png')"
                alt=""
                width="20"
                height="20"
              />
            </router-link>
            <span
              @click="deleteAddOn(props.row.id)"
              class="ml-2"
              style="cursor:pointer;"
            >
              <img
                :src="require('../../assets/Delete.png')"
                alt=""
                width="20"
                height="20"
              />
            </span>
          </b-table-column>
          <template slot="bottom-left">
            <div class="has-text-left">
              Page {{ page }} From
              {{
                Math.ceil(rows / perPage) == '0'
                  ? '1'
                  : Math.ceil(rows / perPage)
              }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      addons: [],
      otp: null,
      total: 0,
      loading: false,
      sortField: 'id',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      page: 1,
      perPage: 10,
      search: '',

      perPages: [
        { text: '5', value: '5' },
        { text: '10', value: '10' },
        { text: '25', value: '25' },
        { text: '50', value: '50' },
        { text: '100', value: '100' },
      ],
    }
  },
  computed: {
    rows() {
      return this.total
    },
  },
  created() {
    this.getAllAddOnsIndex()
  },
  methods: {
    searchAddOns: debounce(function() {
      this.getAllAddOnsIndex()
    }, 500),
    getAllAddOnsIndex: function() {
      const params = [
        `sort_by=${this.sortField}`,
        `order=${this.sortOrder}`,
        `page=${this.page}`,
        `per_page=${this.perPage}`,
        `search=${this.search}`,
      ].join('&')
      this.loading = true

      this.$store.dispatch('getAllAddOnsIndex', params).then(response => {
        if (response.data != null) {
          this.addons = response.data
        } else {
          this.addons = []
        }
        this.total = response.total
        this.loading = false
      })
    },
    onPageChange(page) {
      this.page = page
      this.getAllAddOnsIndex()
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.getAllAddOnsIndex()
    },
    deleteAddOn(id) {
      this.$buefy.dialog.confirm({
        title: 'Konfirmasi',
        message: 'Apakah Anda Yakin Menghapus Data Ini?',
        confirmText: 'Ya',
        cancelText: 'Tidak',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          if (id) {
            this.$store
              .dispatch('deleteAddOn', id)
              .then(() => {
                this.$buefy.toast.open({
                  message: 'Berhasil Hapus Add-On',
                  type: 'is-success',
                })
                this.getAllAddOnsIndex()
              })
              .catch(err => {
                console.log(err)
                this.$buefy.toast.open({
                  message: 'Gagal Hapus Add-On',
                  type: 'is-danger',
                })
              })
          }
        },
      })
    },
  },
}
</script>
